"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.LinkButton = void 0;
var _react = _interopRequireDefault(require("react"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _reactRouterDom = require("react-router-dom");
var sc = _interopRequireWildcard(require("../../../../style-common/styled-component-variables.js"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/*
possible props:
	mobile, maxWidth, maxWidth200, inverted, half, theme
*/

var LinkButton = function LinkButton(props) {
  return "\n    position: relative;\n    text-align: center;\n    display: ".concat(function (props) {
    return props.mobile ? 'none' : 'block';
  }, ";\n    font-size: 16px;\n\n    text-transform: uppercase;\n    text-decoration: none;\n    cursor: pointer;\n\twidth: ").concat(function (props) {
    return props.half ? '50%' : '100%';
  }, ";\n    max-width: ").concat(function (props) {
    return props.maxWidth ? '300px' : props.maxWidth200 ? '200px' : '';
  }, ";\n    margin: ").concat(function (props) {
    return props.inverted ? '10px 0' : props.maxWidth ? '10px auto' : '0';
  }, ";\n    margin-top: ").concat(function (props) {
    return props.marginTop && '10px';
  }, ";\n    padding: 15px;\n    \n    color: ").concat(function (props) {
    return props.inverted ? '#111111' : '#111111';
  }, ";\n    border-radius: ").concat(function (props) {
    return props.mobile ? '0 0' + sc.borderRadius + sc.borderRadius : sc.borderRadius;
  }, ";\n    box-shadow: ").concat(function (props) {
    return props.inverted ? 'none' : '1px 1px 0.5px 0 rgba(70, 70, 70, 0.3)';
  }, ";\n    border: 0.5px solid #111111;\n   \n    ").concat(sc.TabletAndMobile, "{\n   \t display: ").concat(function (props) {
    return props.desktopOnly ? 'none' : 'block';
  }, ";\n    }\n\n\t").concat(sc.Mobile, "{\n\t\twidth: ").concat(function (props) {
    return props.half && '100%';
  }, ";\n\t}\n");
};
exports.LinkButton = LinkButton;
var _default = LinkButton;
exports.default = _default;