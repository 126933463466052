"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VerifyEmailForm = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _WhiteWrapper = require("../styled-components/Wrappers/WhiteWrapper.js");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var VerifyEmailForm = _styledComponents.default.div.withConfig({
  displayName: "VerifyEmailFormstyle__VerifyEmailForm",
  componentId: "sc-2822jc-0"
})([".stackend &{.stackend-resend-activation-code{text-align:left;}", "{box-shadow:none;}.buttons{display:flex;flex-direction:column;justify-content:center;align-items:center;}}"], _WhiteWrapper.WhiteWrapper);
exports.VerifyEmailForm = VerifyEmailForm;