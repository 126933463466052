"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RichContentEditor2 = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
var _quillSnow = _interopRequireDefault(require("react-quill/dist/quill.snow.css"));
var _XcapSitePreview = _interopRequireDefault(require("../styled-components/mixins/XcapSitePreview.style"));
var _XcapResponsiveSubsite = _interopRequireDefault(require("../styled-components/mixins/XcapResponsiveSubsite.style"));
var _ProductPlaceHolder = _interopRequireDefault(require("../styled-components/mixins/ProductPlaceHolder.style"));
var _StackendTheme = require("@stackend/react/theme/StackendTheme");
var _ComponentType = _interopRequireDefault(require("@stackend/react/theme/ComponentType"));
var _ComponentState = _interopRequireDefault(require("@stackend/react/theme/ComponentState"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var RichContentEditor2 = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-rich-content-editor', props.className)
  };
}).withConfig({
  displayName: "RichContentEditor2style__RichContentEditor2",
  componentId: "sc-1rlj4mh-0"
})([".stackend &{.stackend-rich-content-editor-impl{", ";&.quill{.ql-container{border:none;height:auto;}.ql-toolbar{border:none;button,.ql-picker,.ql-picker:hover,.ql-picker-label,.ql-picker-label:hover{color:", ";}button.ql-product{display:flex;align-items:center;}.ql-stroke,.ql-fill{stroke:", ";}}.ql-editor{padding:1em 0.5em;background:", ";height:auto;min-height:2em;max-height:75vh;border:", " solid ", ";border-radius:", ";font-size:", ";font-family:", ";color:", ";&.ql-blank::before{color:", ";}&.ql-blank:focus::before{content:'';}img{height:auto;}", " ", ";", ";.box-layout,.box-layout-item{border:dashed 1px red;min-width:1em;min-height:1em;}}}&.stackend-no-toolbar.quill .ql-toolbar{padding:0;border-top:none;}}&.stackend-rich-content-editor-in-use{.quill{.ql-editor{min-height:13em;}}}width:100%;margin-top:1em;margin-bottom:1em;.stackend-editor-toolbar{font-size:24px;margin-top:0.5em;margin-bottom:0.5em;button{cursor:pointer;}button:disabled{color:gray;}}.medium-editor-element{border:1px solid #cecece;border-radius:5px;padding:0.3em 0.4em;outline:0;h1{font-size:150%;font-weight:bold;margin-top:1em;margin-bottom:1em;}b{font-weight:bold;}i{font-style:italic;}blockquote{display:block;margin-block-start:1em;margin-block-end:1em;margin-inline-start:40px;margin-inline-end:40px;}}}"], _quillSnow.default, function (props) {
  return props.theme.color;
}, function (props) {
  return props.theme.color;
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.TEXT_INPUT, 'backgroundColor');
}, function (props) {
  return props.theme.borderWidth;
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.TEXT_INPUT, 'borderColor');
}, function (props) {
  return props.theme.borderRadius;
}, function (props) {
  return props.theme.fontSize;
}, function (props) {
  return props.theme.fontFamily;
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.TEXT_INPUT, 'color');
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.TEXT_INPUT, 'color', _ComponentState.default.DISABLED);
}, _ProductPlaceHolder.default, _XcapResponsiveSubsite.default, _XcapSitePreview.default);
exports.RichContentEditor2 = RichContentEditor2;