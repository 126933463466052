"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.LinkButton = void 0;
//import parent from "../../../Buttons/LinkButton.js";

//${parent(props)}
var LinkButton = function LinkButton(props) {
  return "\n\t".concat(parent(props), "\n");
};
exports.LinkButton = LinkButton;
var _default = LinkButton;
exports.default = _default;