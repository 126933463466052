"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.AuthorImage = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var sc = _interopRequireWildcard(require("../../style-common/styled-component-variables.js"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var AuthorImage = _styledComponents.default.div.attrs(function (props) {
  return {
    className: 'stackend-author-image' + (props.size ? ' stackend-author-image-' + props.size : '')
  };
}).withConfig({
  displayName: "AuthorImage",
  componentId: "sc-pa8reh-0"
})([".stackend &{display:flex;position:relative;overflow:hidden;width:", ";height:", ";font-size:", ";", "{width:", ";height:", ";font-size:", ";}", "{float:", ";width:", ";height:", ";font-size:", ";}img{width:100%;height:100%;display:block;border-radius:50%;}.stackend-user-tiny,.stackend-user-small,.stackend-user-medium,.stackend-user-large{width:100%;height:100%;text-decoration:none;display:block;}", ";}"], function (props) {
  return sc.profileImageSize[props.size] ? sc.profileImageSize[props.size] : 'inherit';
}, function (props) {
  return sc.profileImageSize[props.size] ? sc.profileImageSize[props.size] : 'inherit';
}, function (props) {
  return sc.profileInitialsFontSize[props.size] ? sc.profileInitialsFontSize[props.size] : 'inherit';
}, sc.TabletAndMobile, function (props) {
  return props.profil && '150px';
}, function (props) {
  return props.profil && '150px';
}, function (props) {
  return props.profil && '55px';
}, sc.Mobile, function (props) {
  return props.large && 'none';
}, function (props) {
  return props.profil ? '50px' : props.large ? '100px' : undefined;
}, function (props) {
  return props.profil ? '50px' : props.large ? '100px' : undefined;
}, function (props) {
  return props.profil ? '25px' : props.large ? '55px' : undefined;
}, function (props) {
  return !!props.theme.AuthorImage && props.theme.AuthorImage(props);
});
exports.AuthorImage = AuthorImage;
var _default = AuthorImage;
exports.default = _default;