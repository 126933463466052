"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.BlogImage = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var BlogImage = _styledComponents.default.div.attrs(function (props) {
  return {
    className: 'stackend-blog-image' + (props.entryId ? ' entry-' + props.entryId : '')
  };
}).withConfig({
  displayName: "BlogImagestyle__BlogImage",
  componentId: "sc-db8gqe-0"
})([".stackend &{&.entry-", "{background:url(", ") center center;background-size:cover;background-repeat:no-repeat;}display:block;width:100%;aspect-ratio:1/1;", ";}"], function (props) {
  return props.entryId;
}, function (props) {
  return props.src;
}, function (props) {
  return !!props.theme.BlogImage && props.theme.BlogImage(props);
});
exports.BlogImage = BlogImage;
var _default = BlogImage;
exports.default = _default;