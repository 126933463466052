"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var settings = {
  /* REMOVE COMMENT FOR GREEN THEME :)
  // Button background colors
  buttonBgColor: "rgba(0,0,0,0.3)",
  buttonBgColorHover: "rgba(0,0,0,1)",
  buttonBgColorActive: "rgba(0,0,0,1)",
  // Button text colors
  buttonTextColor: "#eaeaea",
  buttonHoverTextColor: "#eaeaea",
  buttonActiveTextColor: "#ddd",
  // Accent colors
  AccentColor: "#8bc34a",
  SecondAccentColor: "#ccc",
  // Text colors
  colorTitle: "#000",
  colorText: "#444",
  colorTextAccent: "#8bc34a",
  colorTextInverted: "red",
  // Create date and profile details
  colorLight: "#999",
  
  // Font sizes
  h1: "30px",
  h2: "24px",
  h3: "22px",
  h4: "20px",
  fontSizeBodyText: "18px",
  fontSizeSmall: "16px",
  fontSizeStatusBar: "12px",
  
  borderRadius: "10px",
  borderColor: "#8bc34a",
  // Font types
  fontTitle: "'Open Sans', sans-serif",
  fontNormal: "'Open Sans', sans-serif",
  fontMedium: "'Open Sans', sans-serif; font-weight: bold"
  */
};
var _default = settings;
exports.default = _default;