"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Notification = exports.CommentBody = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
var _styledComponents2 = require("../../styled-components");
var sc = _interopRequireWildcard(require("../../style-common/styled-component-variables"));
var _Rating = require("../../rating/Rating.style");
var _ProductListingItem = require("@stackend/react/shop/ProductListingItem.style");
var _Shop = require("@stackend/react/shop/Shop.style");
var _StackendTheme = require("@stackend/react/theme/StackendTheme");
var _ComponentType = _interopRequireDefault(require("@stackend/react/theme/ComponentType"));
var _styledComponentVariables2 = require("../../style-common/styled-component-variables.js");
var _UserImage = require("../../user/UserImage.style");
var _User = require("../../user/User.style");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var Notification = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-comment-notification', props.className)
  };
}).withConfig({
  displayName: "Notificationstyle__Notification",
  componentId: "sc-1c7qv2z-0"
})([".stackend &{.stackend-notification-container{margin:", ";box-shadow:0px 0px 5px 0 ", ";transition:all 0.2s ease-in-out;border-radius:", ";padding:", ";:hover{transform:scale(1.02);}.stackend-notification-head{display:grid;align-items:center;grid-template-columns:min-content auto min-content;gap:", ";background:", ";margin-bottom:", ";", "{margin:0;}.stackend-notification-head-left{display:grid;place-items:center;gap:{(props) => props.theme.margins.small};}", "{margin:0;border-radius:50px;", "{width:100%;}}.stackend-notification-head-center{display:flex;align-items:flex-start;", "{display:none;place-items:center;vertical-align:top;}.stackend-just-added{}", "{height:unset;}}.stackend-reply-comment-button{display:none;}}}.stackend-notification-body{background:", ";", "{border:0px;width:100% !important;border-radius:0px;margin:0;background:", ";.stackend-product-link.stackend-product-link{display:grid;grid-template-columns:100px 1fr;grid-template-rows:1fr;grid-column-gap:0px;grid-row-gap:0px;width:100%;align-items:center;justify-items:start;grid-template-areas:'image title' 'price shop-now';padding:0;gap:", ";.stackend-square-product-image{grid-area:image;border-radius:", ";img{object-fit:cover;}}", "{grid-area:title;text-align:left;margin:0;font-weight:bold;}", "{grid-area:price;justify-self:center;align-self:end;margin:calc(", ") 0;}", "{grid-area:shop-now;justify-self:end;align-self:end;}}}}.stackend-notification-body-left{grid-area:1 / 1 / 2 / 2;display:grid;place-items:center;}.stackend-notification-body-right{grid-area:1 / 2 / 3 / 3;}.stackend-reply{height:12px;width:auto;background-color:", ";}", ";}"], function (props) {
  return props.theme.margins.small;
}, function (props) {
  return props.theme.color + '7F';
}, function (props) {
  return props.theme.borderRadius;
}, function (props) {
  return props.theme.margins.medium;
}, function (props) {
  return props.theme.margins.medium;
}, function (props) {
  return props.theme.backgroundColor;
}, function (props) {
  return props.theme.margins.small;
}, _styledComponents2.LikeButton, _styledComponents2.AuthorImage, _UserImage.StyledUserImage, _styledComponents2.AuthorName, _User.ProfileWrapper, function (props) {
  return props.theme.backgroundColor;
}, _ProductListingItem.ProductListingItem, function (props) {
  return props.theme.backgroundColor;
}, function (props) {
  return props.theme.margins.medium;
}, function (props) {
  return props.theme.borderRadius;
}, _Shop.Title, _Shop.Price, function (props) {
  return props.theme.margins.small + ' + ' + props.theme.borderWidth;
}, _Shop.ShopNowButton, _styledComponentVariables2.buttonBgColor, function (props) {
  return typeof props.theme.Comment === 'function' && props.theme.Comment(props);
});
exports.Notification = Notification;
var CommentBody = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-comment-body', props.className)
  };
}).withConfig({
  displayName: "Notificationstyle__CommentBody",
  componentId: "sc-1c7qv2z-1"
})([".stackend &{.stackend-user-small{float:left;margin-right:", ";}.stackend-author-name{overflow:hidden;margin-bottom:", ";margin-right:", ";}iframe,.stackend-rich-content img{display:block;clear:both;max-width:100%;}.xcap-responsive-subsite{position:relative;z-index:99;display:inline-block;height:0;width:100%;padding-bottom:56.4% !important;margin:5px 0;iframe{position:absolute;top:0;left:0;width:100%;height:100%;max-width:unset;margin-bottom:0;padding-top:0;}}", "{margin-left:2em;}", ";}"], function (props) {
  return props.theme.margins.small;
}, function (props) {
  return props.theme.margins.medium;
}, function (props) {
  return props.theme.margins.small;
}, _Rating.Rating, function (props) {
  return !!props.theme.CommentBody && props.theme.CommentBody(props);
});
exports.CommentBody = CommentBody;