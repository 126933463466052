"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AIPreviewThrobber = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var AIPreviewThrobber = _styledComponents.default.div.withConfig({
  displayName: "AIThrobber__AIPreviewThrobber",
  componentId: "sc-zugfwx-0"
})([".stackend &{aspect-ratio:16/9;width:100%;height:auto;background-repeat:no-repeat;background-image:url(", ");", ";}"], require('./ai-throbber.svg'), function (props) {
  return !!props.theme.ModalThrobber && props.theme.ModalThrobber(props);
});
exports.AIPreviewThrobber = AIPreviewThrobber;