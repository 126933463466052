"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.AuthorNameInSearchList = exports.AuthorName = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var sc = _interopRequireWildcard(require("../../style-common/styled-component-variables.js"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var AuthorName = _styledComponents.default.div.attrs({
  className: 'stackend-author-name'
}).withConfig({
  displayName: "AuthorName",
  componentId: "sc-144vnfa-0"
})([".stackend &{display:", ";vertical-align:top;margin:", ";width:", ";.stackend-user-name{font-size:", ";}.stackend-user-tiny,.stackend-user-small,.stackend-user-medium,.stackend-user-large{width:initial;height:initial;display:inline-block;text-decoration:none;}", ";}"], function (props) {
  return props.inlineBlock ? 'inline-block' : 'flex';
}, function (props) {
  return props.inlineBlock ? '0' : props.clean ? '0' : props.small ? '5px 0 0 0' : props.large ? '5px 0 10px' : '0';
}, function (props) {
  return props.inlineBlock ? 'initial' : props.blog ? '100%' : '';
}, function (props) {
  return props.profil ? sc.h1 : props.large ? sc.h1 : '1em';
}, function (props) {
  return !!props.theme.AuthorName && props.theme.AuthorName(props);
});
exports.AuthorName = AuthorName;
var AuthorNameInSearchList = (0, _styledComponents.default)(AuthorName).withConfig({
  displayName: "AuthorName__AuthorNameInSearchList",
  componentId: "sc-144vnfa-1"
})([".stackend &{display:flex;position:", ";top:", ";right:", ";margin:", ";color:#565656;font-size:14px;text-overflow:ellipsis;.stackend-user-name{font-size:14px;margin-left:5px;}", "{position:relative;top:inherit;right:inherit;}", ";}"], function (props) {
  return props.search ? 'absolute' : 'relative';
}, function (props) {
  return props.search && 'calc(55% - 14px)';
}, function (props) {
  return props.search && '50px';
}, function (props) {
  return props.clean && '0';
}, sc.Mobile, function (props) {
  return !!props.theme.AuthorNameInSearchList && props.theme.AuthorNameInSearchList(props);
});
exports.AuthorNameInSearchList = AuthorNameInSearchList;
var _default = AuthorName;
exports.default = _default;