"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BlogEditorTitle = exports.BlogEditorHeader = exports.BlogEditorButtonBarStart = exports.BlogEditorButtonBarEnd = exports.BlogEditorButtonBar = exports.BlogEditor = exports.AIBlogEditorErrorWrapper = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _MediaFileChooserButton = require("../../media/MediaFileChooserButton.style");
var _Button = _interopRequireDefault(require("../../styled-components/Buttons/Button"));
var _Separator = require("../../ui/Separator.style");
var _RichContentEditor = require("../../editor/RichContentEditor2.style");
var _PollInput = require("../poll/PollInput.style");
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
var _EventInput = _interopRequireDefault(require("../event/EventInput.style"));
var _media = require("../../styled-components/media");
var _StackendTheme = require("@stackend/react/theme/StackendTheme");
var _ComponentType = _interopRequireDefault(require("@stackend/react/theme/ComponentType"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var BlogEditorButtonBar = _styledComponents.default.div.attrs({
  className: 'stackend-blog-editor-button-bar'
}).withConfig({
  displayName: "BlogEditorstyle__BlogEditorButtonBar",
  componentId: "sc-zmkd09-0"
})([".stackend &{display:flex;flex-direction:row;", "{flex-direction:column;}align-content:center;align-items:center;justify-content:space-between;gap:1em;padding-top:1em;}"], _media.media.mobileScreen);
exports.BlogEditorButtonBar = BlogEditorButtonBar;
var BlogEditorButtonBarStart = _styledComponents.default.div.attrs({
  className: 'stackend-blog-editor-button-bar-start'
}).withConfig({
  displayName: "BlogEditorstyle__BlogEditorButtonBarStart",
  componentId: "sc-zmkd09-1"
})([".stackend &{justify-self:start;display:flex;flex-direction:row;align-items:center;gap:1em;}"]);
exports.BlogEditorButtonBarStart = BlogEditorButtonBarStart;
var BlogEditorButtonBarEnd = _styledComponents.default.div.attrs({
  className: 'stackend-blog-editor-button-bar-end'
}).withConfig({
  displayName: "BlogEditorstyle__BlogEditorButtonBarEnd",
  componentId: "sc-zmkd09-2"
})([".stackend &{justify-self:end;align-self:flex-end;display:flex;flex-direction:row;align-items:center;gap:1em;", "{justify-self:end;margin:0;}}"], _Button.default);
exports.BlogEditorButtonBarEnd = BlogEditorButtonBarEnd;
var BlogEditor = _styledComponents.default.form.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-blog-editor', props.className)
  };
}).withConfig({
  displayName: "BlogEditorstyle__BlogEditor",
  componentId: "sc-zmkd09-3"
})([".stackend &{border-radius:", ";border:", ";overflow:hidden;padding:", ";background-color:", ";color:", ";", "{margin:0;.stackend-rich-content-editor-impl.quill{.ql-toolbar,.ql-container{border-radius:0;border-left:none;border-right:none;}.ql-toolbar{.ql-formats{.ql-product{.material-icons{background:", ";color:", ";padding:6px;border-radius:5px;}}}}.ql-editor{min-height:8em;img{max-width:100%;}.stackend-product-placeholder{width:20em;flex-direction:column;gap:1em;text-align:center;&.stackend-product-placeholder-compact,&.stackend-product-placeholder-full{flex-direction:row;width:100%;img{height:20em;}}.stackend-product-placeholder-title{margin-left:0;}}}}}&.stackend-blog-editor-poll-visible,&.stackend-blog-editor-event-visible{", "{border-top:", " solid ", ";}}", ",", "{margin:1em;}.stackend-error-message{color:", ";}.stackend-react-select{margin-top:0.5em;}.stackend-react-select__multi-value{margin-right:0.5em;padding:0.2em 0.5em;}", ";}"], function (props) {
  return props.theme.borderRadius;
}, function (props) {
  return (0, _StackendTheme.getComponentBorder)(props.theme, _ComponentType.default.BOX);
}, function (props) {
  return props.theme.margins.medium;
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'backgroundColor');
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'color');
}, _RichContentEditor.RichContentEditor2, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'backgroundColor');
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'color');
}, BlogEditorButtonBar, function (props) {
  return props.theme.borderWidth;
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'borderColor');
}, _PollInput.PollInput, _EventInput.default, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.ACCENT, 'color');
}, function (props) {
  return !!props.theme.BlogEditor && props.theme.BlogEditor(props);
});
exports.BlogEditor = BlogEditor;
var BlogEditorTitle = _styledComponents.default.input.attrs({
  className: 'stackend-blog-editor-title'
}).withConfig({
  displayName: "BlogEditorstyle__BlogEditorTitle",
  componentId: "sc-zmkd09-4"
})([".stackend &{font-size:", ";border:none;width:100%;padding:0.25em;&:focus::placeholder{color:transparent;}", ";}"], function (props) {
  return (0, _StackendTheme.headingFontSize)(props.theme, _ComponentType.default.H3);
}, function (props) {
  return !!props.theme.BlogEditorTitle && props.theme.BlogEditorTitle(props);
});
exports.BlogEditorTitle = BlogEditorTitle;
var BlogEditorHeader = _styledComponents.default.div.attrs({
  className: 'stackend-blog-editor-header'
}).withConfig({
  displayName: "BlogEditorstyle__BlogEditorHeader",
  componentId: "sc-zmkd09-5"
})([".stackend &{display:flex;flex-direction:column;align-items:center;margin:", ";", "{height:", ";}", "{width:100%;font-size:2em;padding:0;margin:0 auto;border:1px dashed lightgrey;margin-bottom:0.5em;background:#f1f1f1;", ".stackend-media-file-chooser-button-image{", ";.material-icons{", ";}", ";}}}"], function (props) {
  return props.theme.margins.normal;
}, _Separator.VSeparator, function (props) {
  return !!!props.hasImage ? '2em' : '6.5em';
}, _MediaFileChooserButton.MediaFileChooserButton, _MediaFileChooserButton.MediaFileChooserButtonImage, function (props) {
  return !!!props.hasImage && 'width: 1.25em; height: 1.25em;';
}, function (props) {
  return !!!props.hasImage && 'font-size: 1em; top: 0.125em; left: 0.125em;';
}, function (props) {
  return !!props.hasImage && '  background-size: cover; width: 100%; height:4em;';
});
exports.BlogEditorHeader = BlogEditorHeader;
var AIBlogEditorErrorWrapper = _styledComponents.default.div.attrs({
  className: 'stackend-ai-blog-editor-error'
}).withConfig({
  displayName: "BlogEditorstyle__AIBlogEditorErrorWrapper",
  componentId: "sc-zmkd09-6"
})([".stackend &{background:", ";color:", ";border-radius:", ";padding:", ";border:", " solid ", ";margin:", ";}"], function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.ACCENT, 'backgroundColor');
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.ACCENT, 'color');
}, function (props) {
  return props.theme.borderRadius;
}, function (props) {
  return props.theme.margins.medium;
}, function (props) {
  return props.theme.borderWidth;
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.ACCENT, 'borderColor');
}, function (props) {
  return props.theme.margins.medium;
});
exports.AIBlogEditorErrorWrapper = AIBlogEditorErrorWrapper;