"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CommentBody = exports.CommentActions = exports.Comment = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var sc = _interopRequireWildcard(require("../../style-common/styled-component-variables.js"));
var _RatingStyle = require("../../rating/Rating.style.js");
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
var _styledComponents2 = require("../../styled-components");
var _media = require("../../styled-components/media");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var CommentBody = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-comment-body', props.className)
  };
}).withConfig({
  displayName: "Commentstyle__CommentBody",
  componentId: "sc-1r9t928-0"
})([".stackend &{.stackend-user-small{float:left;margin-right:", ";}.stackend-author-name{overflow:hidden;margin-bottom:", ";margin-right:", ";}iframe,.stackend-rich-content img{display:block;clear:both;margin-bottom:", ";padding-top:", ";max-width:820px;", "{max-width:100%;}}.xcap-responsive-subsite{position:relative;z-index:99;display:inline-block;height:0;width:100%;padding-bottom:56.4% !important;margin:5px 0;iframe{position:absolute;top:0;left:0;width:100%;height:100%;max-width:unset;margin-bottom:0;padding-top:0;}}", "{margin-left:2em;}", ";}"], function (props) {
  return props.theme.margins.small;
}, function (props) {
  return props.theme.margins.medium;
}, function (props) {
  return props.theme.margins.small;
}, function (props) {
  return props.theme.margins.small;
}, function (props) {
  return props.theme.margins.small;
}, sc.TabletAndMobile, _RatingStyle.Rating, function (props) {
  return !!props.theme.CommentBody && props.theme.CommentBody(props);
});
exports.CommentBody = CommentBody;
var CommentActions = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-comment-actions', props.className)
  };
}).withConfig({
  displayName: "Commentstyle__CommentActions",
  componentId: "sc-1r9t928-1"
})([".stackend &{display:flex;align-items:center;flex-direction:row;gap:", ";", "{.stackend-reply-comment-button span{display:none;}}}"], function (props) {
  return props.theme.margins.small;
}, _media.media.mobilePortrait);
exports.CommentActions = CommentActions;
var Comment = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-comment', props.className)
  };
}).withConfig({
  displayName: "Commentstyle__Comment",
  componentId: "sc-1r9t928-2"
})([".stackend &{overflow:hidden;display:grid;grid-template-columns:min-content auto 1fr auto;grid-template-rows:auto auto;grid-template-areas:'author-image author-name created-date actions' '- text text text';grid-gap:", " ", ";align-items:center;border-bottom:", " solid ", ";margin:", " 0 0 ", ";padding:", " 0;box-sizing:border-box;&.stackend-comment-has-rating{grid-template-columns:min-content auto auto 1fr auto;grid-template-areas:'author-image author-name rating created-date actions' '- text text text text';", "{grid-template-columns:min-content 1fr auto auto;grid-template-areas:'author-image author-name author-name actions' '- rating rating rating' '- text text text';}}&.stackend-highlight{@keyframes fadeIn{0%{opacity:0;}100%{opacity:1;}}animation:fadeIn 2s;}&.stackend-comment-selected{background:", ";}&:first-child{margin-top:0;}&:last-child{border-bottom:none;padding-bottom:0;}", "{grid-area:author-image;}", "{grid-area:author-name;align-self:center;", "{margin-left:", ";}}", "{grid-area:rating;}", "{grid-area:created-date;&:before{margin-left:0;margin-right:0.5em;}}", "{grid-area:text;}", "{grid-area:actions;align-self:start;}", "{", "{display:none;}}", ";}"], function (props) {
  return props.theme.margins.small;
}, function (props) {
  return props.theme.margins.medium;
}, function (props) {
  return props.theme.borderWidth;
}, function (props) {
  return props.theme.borderColor + '10';
}, function (props) {
  return props.theme.margins.small;
}, function (props) {
  return props.reply ? props.theme.margins.medium : '0';
}, function (props) {
  return props.theme.margins.small;
}, _media.media.mobileScreen, sc.discreteBackgroundColor, _styledComponents2.AuthorImage, _styledComponents2.AuthorName, _RatingStyle.Rating, function (props) {
  return props.theme.margins.medium;
}, _RatingStyle.Rating, _styledComponents2.CreateDate, CommentBody, CommentActions, _media.media.mobilePortrait, _styledComponents2.CreateDate, function (props) {
  return typeof props.theme.Comment === 'function' && props.theme.Comment(props);
});
exports.Comment = Comment;