"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _LinkButton = require("./Buttons/LinkButton.js");
var _styledComponentVariables = _interopRequireDefault(require("./styled-component-variables.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var components = {
  LinkButton: _LinkButton.LinkButton,
  settings: _styledComponentVariables.default
};
var _default = components;
exports.default = _default;