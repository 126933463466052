"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Initials = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _StackendTheme = require("@stackend/react/theme/StackendTheme");
var _ComponentType = _interopRequireDefault(require("@stackend/react/theme/ComponentType"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var Initials = _styledComponents.default.div.attrs({
  className: 'stackend-initials'
}).withConfig({
  displayName: "Initials",
  componentId: "sc-k19g3h-0"
})([".stackend &{display:flex;align-items:center;justify-content:center;margin:0;background:", ";color:", ";border-radius:100%;text-align:center;width:100%;height:100%;aspect-ratio:1 / 1;", ";}"], function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.ACCENT, 'backgroundColor');
}, function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.ACCENT, 'color');
}, function (props) {
  return !!props.theme.Initials && props.theme.Initials(props);
});
exports.Initials = Initials;
var _default = Initials;
exports.default = _default;