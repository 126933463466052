"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styledComponents = require("styled-components");
var _media = _interopRequireDefault(require("@stackend/react/style-common/media"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var StackendReactModal = (0, _styledComponents.css)(["&.StackendModalPortal{.ReactModal__Overlay{z-index:2000;position:fixed;top:0;left:0;width:100vw;height:100vh;background:rgba(0,0,0,0.4) !important;}.ReactModal__Content{position:absolute;top:50%;left:50%;transform:translateX(-50%) translateY(-50%);min-width:50%;max-height:90vh;max-width:90vw;border:", " solid ", ";border-radius:", ";box-shadow:0 0 5px 0 ", ";background:", ";overflow:hidden;outline:none;box-sizing:border-box;padding:", ";&.stackend-modal-not-scrolled{height:75vh;", "{height:100vh;height:-webkit-fill-available;}}&.stackend-modal-scrolled{overflow:auto;}&.stackend-modal-full-screen{width:100vw;height:100vh;height:-webkit-fill-available;top:0;left:0;max-width:unset;max-height:unset;border:none;border-radius:unset;transform:none;padding:0;.stackend-modal-content{padding:0;}}&.stackend-modal-not-scrolled.stackend-embed-media,&.stackend-modal-not-scrolled.stackend-product-select-modal{height:auto;}", "{width:100vw;height:100vh;height:-webkit-fill-available;max-height:unset;max-width:unset;top:0;left:0;border:0;border-radius:0;transform:none;padding:1em;}h1.stackend-dialog-title-bar{button:last-child{float:right;margin-right:0;.material-icons{font-size:20px;}}}.stackend-button-box{margin-top:40px;button{margin:0 1em;&:first-child{margin-left:0;}&:last-child{margin-right:0;float:right;}}}}}"], function (props) {
  return props.theme.borderWidth;
}, function (props) {
  return props.theme.borderColor;
}, function (props) {
  return props.theme.borderRadius;
}, function (props) {
  return props.theme.borderColor;
}, function (props) {
  return props.theme.backgroundColor || '#fff';
}, function (props) {
  return props.theme.margins.large;
}, _media.default.tabletScreen, _media.default.tabletScreen);
var _default = StackendReactModal;
exports.default = _default;