"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProfileMenuProfileWrapper = exports.ProfileMenuItems = exports.ProfileMenuItem = exports.ProfileMenuFeed = exports.ProfileMenu = exports.ProfileContentScroll = exports.ProfileContent = exports.MIN_WIDTH = exports.ICON_SIZE = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
var _styledComponentVariables = require("../style-common/styled-component-variables");
var _Close = require("../ui/Close.style");
var _AddToBasketComments = require("../comments/AddToBasketComments.style");
var _media = _interopRequireDefault(require("@stackend/react/style-common/media"));
var _CounterBadge = _interopRequireDefault(require("../ui/CounterBadge.style"));
var _ProfileMenuBasket = require("./ProfileMenuBasket.style");
var _ProfileMenuProduct = require("./ProfileMenuProduct.style");
var _StackendTheme = require("@stackend/react/theme/StackendTheme");
var _ComponentType = _interopRequireDefault(require("@stackend/react/theme/ComponentType"));
var _styledComponents2 = require("../styled-components");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var ICON_SIZE = '2em';
exports.ICON_SIZE = ICON_SIZE;
var MIN_WIDTH = '30em';
exports.MIN_WIDTH = MIN_WIDTH;
var ProfileContent = _styledComponents.default.div.attrs(function (props) {
  return {
    className: 'stackend-profile-menu-content'
  };
}).withConfig({
  displayName: "ProfileMenustyle__ProfileContent",
  componentId: "sc-3wbs4t-0"
})([".stackend &{background:", ";display:flex;flex-direction:column;width:", ";height:100vh;box-shadow:0 0 5px 0 ", ";z-index:", ";", "{align-self:end;margin-top:1rem;margin-right:1rem;", "{font-size:2em;}}", "{width:100vw;height:100%;}", " ", ";}"], function (props) {
  return props.theme.backgroundColor;
}, MIN_WIDTH, function (props) {
  return props.theme.borderColor + '7F';
}, _styledComponentVariables.zIndexes.userMenu + 1, _Close.CloseButton, _media.default.tabletScreen, _media.default.tabletScreen, _ProfileMenuBasket.ProfileMenuBasket, _ProfileMenuProduct.ProfileMenuProduct);
exports.ProfileContent = ProfileContent;
var ProfileContentScroll = _styledComponents.default.div.attrs(function (props) {
  return {
    className: 'stackend-profile-menu-content-scroll'
  };
}).withConfig({
  displayName: "ProfileMenustyle__ProfileContentScroll",
  componentId: "sc-3wbs4t-1"
})([".stackend &{overflow-y:auto;overflow-x:hidden;display:flex;flex-direction:column;width:100%;height:100%;padding:", ";", "{padding-bottom:calc(", " + ", ");}", "{flex:1 0 100%;}.stackend-basket{padding:0;overflow:initial;.stackend-basket-list-item.stackend-basket-list-item{padding-left:0;padding-right:0;margin:0;}}}"], function (props) {
  return props.theme.margins.medium || '1em';
}, _media.default.tabletScreen, ICON_SIZE, ICON_SIZE, _AddToBasketComments.AddToBasketComments);
exports.ProfileContentScroll = ProfileContentScroll;
var ProfileMenuItems = _styledComponents.default.ol.attrs(function (props) {
  return {
    className: 'stackend-profile-menu-items'
  };
}).withConfig({
  displayName: "ProfileMenustyle__ProfileMenuItems",
  componentId: "sc-3wbs4t-2"
})([".stackend &{display:flex;flex-direction:column;place-self:center;gap:", ";padding:", ";background:", ";box-shadow:0 0 5px 0 ", ";border-top-left-radius:", ";border-bottom-left-radius:", ";}"], function (props) {
  return props.theme.margins.small || '0.5em';
}, function (props) {
  return props.theme.margins.small || '0.5em';
}, function (props) {
  return props.theme.backgroundColor;
}, function (props) {
  return props.theme.borderColor + '7F';
}, function (props) {
  return props.theme.borderRadius;
}, function (props) {
  return props.theme.borderRadius;
});
exports.ProfileMenuItems = ProfileMenuItems;
var ProfileMenu = _styledComponents.default.nav.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-profile-menu', props.className)
  };
}).withConfig({
  displayName: "ProfileMenustyle__ProfileMenu",
  componentId: "sc-3wbs4t-3"
})([".stackend &{position:fixed;top:50%;transform:translate(0,-50%);right:0;display:flex;z-index:", ";&.stackend-profile-menu-expanded{height:100%;top:0;transform:none;}@keyframes slide-menu{0%{transform:translateX(0%);}100%{transform:translateX( calc( -100% + ", " ) );}}&.stackend-profile-menu-expanded{right:0;}&.stackend-profile-menu-closing{}&.stackend-profile-menu-collapsed{", "{box-shadow:none;display:none;}}", "{&.stackend-profile-menu-expanded{width:100vw;right:unset;animation:none;justify-content:center;", "{margin-left:", ";}", "{z-index:", ";text-shadow:0 0 0.5em ", ";}}}.stackend-shop-product-module-horizontal{.stackend-product-name{font-size:0.8em;}}}"], _styledComponentVariables.zIndexes.userMenu, function (props) {
  return props.theme.margins.small + ' + ' + ICON_SIZE + ' + ' + props.theme.margins.small;
}, ProfileContent, _media.default.tabletScreen, ProfileMenuItems, function (props) {
  return props.theme.margins.small;
}, _Close.CloseButton, _styledComponentVariables.zIndexes.userMenu + 2, function (props) {
  return props.theme.backgroundColor;
});
exports.ProfileMenu = ProfileMenu;
var ProfileMenuItem = _styledComponents.default.li.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-profile-menu-item', props.className)
  };
}).withConfig({
  displayName: "ProfileMenustyle__ProfileMenuItem",
  componentId: "sc-3wbs4t-4"
})([".stackend &{list-style:none;&.stackend-profile-menu-selected{> a{color:", ";background:", ";}}> a{width:", ";height:", ";line-height:1em;display:flex;align-items:center;justify-content:center;border-radius:100%;border:solid ", " ", ";text-decoration:none;color:", ";position:relative;", "{position:absolute;font-size:0.5em;font-weight:bold;top:-1em;right:-1em;}}}"], function (props) {
  return props.theme.backgroundColor;
}, function (props) {
  return props.theme.color;
}, ICON_SIZE, ICON_SIZE, function (props) {
  return props.theme.borderWidth;
}, function (props) {
  return props.theme.color;
}, function (props) {
  return props.theme.color;
}, _CounterBadge.default);
exports.ProfileMenuItem = ProfileMenuItem;
var ProfileMenuFeed = _styledComponents.default.div.attrs(function (props) {
  return {
    className: 'stackend-profile-menu-feed'
  };
}).withConfig({
  displayName: "ProfileMenustyle__ProfileMenuFeed",
  componentId: "sc-3wbs4t-5"
})([".stackend &{margin-top:0.7em;.stackend-blog-editor{background:unset;border:0px;}", "{display:flex;flex-direction:column;grid-template-columns:unset;place-items:unset;}}"], _styledComponents2.FeedWrapper);
exports.ProfileMenuFeed = ProfileMenuFeed;
var ProfileMenuProfileWrapper = _styledComponents.default.div.attrs(function (props) {
  return {
    className: 'stackend-profile-menu-profile-wrapper'
  };
}).withConfig({
  displayName: "ProfileMenustyle__ProfileMenuProfileWrapper",
  componentId: "sc-3wbs4t-6"
})([".stackend &{padding:0.7em;background:", ";border-radius:", ";border:solid ", " transparent;}"], function (props) {
  return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.BOX, 'backgroundColor');
}, function (props) {
  return props.theme.borderRadius;
}, function (props) {
  return props.theme.borderWidth;
});
exports.ProfileMenuProfileWrapper = ProfileMenuProfileWrapper;