"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ProductSelect = exports.ProductOption = exports.ProductName = exports.ProductLabel = exports.ProductHandle = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var ProductLabel = _styledComponents.default.div.withConfig({
  displayName: "ProductSelectstyle__ProductLabel",
  componentId: "sc-1dguwi6-0"
})([""]);
exports.ProductLabel = ProductLabel;
var ProductName = _styledComponents.default.span.withConfig({
  displayName: "ProductSelectstyle__ProductName",
  componentId: "sc-1dguwi6-1"
})([""]);
exports.ProductName = ProductName;
var ProductHandle = _styledComponents.default.span.withConfig({
  displayName: "ProductSelectstyle__ProductHandle",
  componentId: "sc-1dguwi6-2"
})([""]);
exports.ProductHandle = ProductHandle;
var ProductOption = _styledComponents.default.div.withConfig({
  displayName: "ProductSelectstyle__ProductOption",
  componentId: "sc-1dguwi6-3"
})([""]);
exports.ProductOption = ProductOption;
var ProductSelect = _styledComponents.default.div.withConfig({
  displayName: "ProductSelectstyle__ProductSelect",
  componentId: "sc-1dguwi6-4"
})([".stackend &{", "{cursor:pointer;display:flex;flex-direction:row;align-items:center;background-color:", ";color:", ";img{margin-right:1em;}", "{", ",", "{display:block;}", "{font-size:0.8em;}}}}"], ProductOption, function (props) {
  return props.theme.backgroundColor;
}, function (props) {
  return props.theme.color;
}, ProductLabel, ProductName, ProductHandle, ProductHandle);
exports.ProductSelect = ProductSelect;
var _default = ProductSelect;
exports.default = _default;