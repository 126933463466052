"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Profile = exports.Name = exports.Location = exports.Gender = exports.Description = exports.Birthday = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _classNames = _interopRequireDefault(require("@stackend/react/style-common/classNames"));
var _UserImage = require("./UserImage.style");
var _StackendTheme = require("@stackend/react/theme/StackendTheme");
var _ComponentType = _interopRequireDefault(require("@stackend/react/theme/ComponentType"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var Profile = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-user-profile', props.className)
  };
}).withConfig({
  displayName: "Profilestyle__Profile",
  componentId: "sc-110ltee-0"
})([".stackend &{display:grid;gap:1em;grid-template-columns:auto auto 1fr;grid-template-areas:'image name name' 'image gender birthday' 'description description description';", "{grid-area:image;box-shadow:0 0 5px 0 ", ";}}"], _UserImage.StyledUserImage, function (props) {
  return props.theme.borderColor + '7F';
});
exports.Profile = Profile;
var Name = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-user-name', props.className)
  };
}).withConfig({
  displayName: "Profilestyle__Name",
  componentId: "sc-110ltee-1"
})([".stackend &{font-size:", ";grid-area:name;align-self:start;}"], function (props) {
  return (0, _StackendTheme.headingFontSize)(props.theme, _ComponentType.default.H3);
});
exports.Name = Name;
var Location = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-user-location', props.className)
  };
}).withConfig({
  displayName: "Profilestyle__Location",
  componentId: "sc-110ltee-2"
})([".stackend &{grid-area:location;}"]);
exports.Location = Location;
var Gender = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-user-gender', props.className)
  };
}).withConfig({
  displayName: "Profilestyle__Gender",
  componentId: "sc-110ltee-3"
})([".stackend &{grid-area:gender;align-self:end;}"]);
exports.Gender = Gender;
var Birthday = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-user-birthday', props.className)
  };
}).withConfig({
  displayName: "Profilestyle__Birthday",
  componentId: "sc-110ltee-4"
})([".stackend &{grid-area:birthday;align-self:end;}"]);
exports.Birthday = Birthday;
var Description = _styledComponents.default.div.attrs(function (props) {
  return {
    className: (0, _classNames.default)('stackend-user-description', props.className)
  };
}).withConfig({
  displayName: "Profilestyle__Description",
  componentId: "sc-110ltee-5"
})([".stackend &{grid-area:description;}"]);
exports.Description = Description;