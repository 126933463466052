"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.zIndexes = exports.sendBorderColor = exports.profileInitialsFontSize = exports.profileImageSize = exports.pollTextColor = exports.maxContentWidthLargeDesktop = exports.maxContentWidthDesktop = exports.maxContentBlogWidth = exports.h4 = exports.h3 = exports.h2 = exports.h1 = exports.fontWeight = exports.fontTitle = exports.fontSizeStatusBar = exports.fontSizeSmall = exports.fontSizeBodyText = exports.fontNormal = exports.fontMedium = exports.discreteBackgroundColor = exports.default = exports.colorTitle = exports.colorTextListed = exports.colorTextInverted = exports.colorTextAccent = exports.colorText = exports.colorLight = exports.buttonTextColor = exports.buttonHoverTextColor = exports.buttonFontSize = exports.buttonFont = exports.buttonBoxShadow = exports.buttonBorder = exports.buttonBgColorInverted = exports.buttonBgColorHoverInverted = exports.buttonBgColorHover = exports.buttonBgColorActiveInverted = exports.buttonBgColorActive = exports.buttonBgColor = exports.buttonActiveTextColor = exports.boxShadow = exports.borderRadius = exports.borderColor = exports.border = exports.blogTitle = exports.backgroundColor = exports.arrow = exports.TabletAndMobile = exports.Tablet = exports.SecondAccentColor = exports.Mobile = exports.BigDesktop = exports.BackArrowColor = exports.AccentColor = void 0;
var _object = _interopRequireDefault(require("lodash/object"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var BigDesktop = '@media screen and (min-width: 1300px)';
exports.BigDesktop = BigDesktop;
var Tablet = '@media screen and (min-width: 767px) and (max-width: 991px)';
exports.Tablet = Tablet;
var TabletAndMobile = '@media screen and (max-width: 991px)';
exports.TabletAndMobile = TabletAndMobile;
var Mobile = '@media screen and (max-width: 767px)';
exports.Mobile = Mobile;
var boxShadow = '2px 2px 20px rgba(70, 70, 70, 0.5)';
exports.boxShadow = boxShadow;
var borderColor = function borderColor(props) {
  return _object.default.get(props, 'theme.settings.borderColor', '#e9e9e9');
};
exports.borderColor = borderColor;
var sendBorderColor = function sendBorderColor(props) {
  return _object.default.get(props, 'theme.settings.sendBorderColor', '#00B2E8');
};
exports.sendBorderColor = sendBorderColor;
var border = function border(props) {
  return "1px solid ".concat(borderColor(props));
};
exports.border = border;
var borderRadius = '3px';
exports.borderRadius = borderRadius;
var maxContentWidthDesktop = '960px';
exports.maxContentWidthDesktop = maxContentWidthDesktop;
var maxContentWidthLargeDesktop = '1120px';
exports.maxContentWidthLargeDesktop = maxContentWidthLargeDesktop;
var maxContentBlogWidth = '790px';

// Text
exports.maxContentBlogWidth = maxContentBlogWidth;
var colorTitle = function colorTitle(props) {
  return _object.default.get(props, 'theme.style.colorTitle', _object.default.get(props, 'theme.settings.colorTitle', '#565656'));
};
exports.colorTitle = colorTitle;
var colorText = function colorText(props) {
  return _object.default.get(props, 'theme.style.colorText', _object.default.get(props, 'theme.settings.colorText', '#565656'));
};
exports.colorText = colorText;
var colorLight = function colorLight(props) {
  return _object.default.get(props, 'theme.style.discreteColor', _object.default.get(props, 'theme.settings.colorLight', '#a2a2a2'));
};
exports.colorLight = colorLight;
var colorTextInverted = function colorTextInverted(props) {
  return _object.default.get(props, 'theme.settings.colorTextInverted', '#ffffff');
};
exports.colorTextInverted = colorTextInverted;
var colorTextListed = function colorTextListed(props) {
  return _object.default.get(props, 'theme.settings.colorTextListed', '#383838');
};
exports.colorTextListed = colorTextListed;
var colorTextAccent = function colorTextAccent(props) {
  return _object.default.get(props, 'theme.style.accentColor', _object.default.get(props, 'theme.settings.colorTextAccent', '#00B2E8'));
};
exports.colorTextAccent = colorTextAccent;
var backgroundColor = function backgroundColor(props) {
  return _object.default.get(props, 'theme.style.backgroundColor', _object.default.get(props, 'theme.settings.backgroundColor', '#ffffff'));
};
exports.backgroundColor = backgroundColor;
var discreteBackgroundColor = function discreteBackgroundColor(props) {
  return _object.default.get(props, 'theme.style.discreteBackgroundColor', _object.default.get(props, 'theme.settings.discreteBackgroundColor', '#B1B1B1'));
};

// Fonts
exports.discreteBackgroundColor = discreteBackgroundColor;
var fontNormal = function fontNormal(props) {
  return _object.default.get(props, 'theme.style.fontNormal', _object.default.get(props, 'theme.settings.fontNormal', "'Helvetica Neue', 'Helvetica', 'Arial', 'Sans-serif'"));
};
exports.fontNormal = fontNormal;
var fontMedium = function fontMedium(props) {
  return _object.default.get(props, 'theme.settings.fontMedium', "'Helvetica Neue', 'Helvetica', 'Arial', 'Sans-serif'; font-weight: bold");
};
exports.fontMedium = fontMedium;
var fontTitle = function fontTitle(props) {
  return _object.default.get(props, 'theme.style.fontTitle', _object.default.get(props, 'theme.settings.fontTitle', "'Helvetica Neue', 'Helvetica', 'Arial', 'Sans-serif'"));
};

// Font sizes
exports.fontTitle = fontTitle;
var h1 = function h1(props) {
  return _object.default.get(props, 'theme.style.h1', _object.default.get(props, 'theme.settings.h1', '24px'));
};
exports.h1 = h1;
var h2 = function h2(props) {
  return _object.default.get(props, 'theme.style.h2', _object.default.get(props, 'theme.settings.h2', '22px'));
};
exports.h2 = h2;
var h3 = function h3(props) {
  return _object.default.get(props, 'theme.style.h3', _object.default.get(props, 'theme.settings.h3', '20px'));
};
exports.h3 = h3;
var h4 = function h4(props) {
  return _object.default.get(props, 'theme.style.h4', _object.default.get(props, 'theme.settings.h4', '18px'));
};
exports.h4 = h4;
var fontSizeBodyText = function fontSizeBodyText(props) {
  return _object.default.get(props, 'theme.style.fontSizeBodyText', _object.default.get(props, 'theme.settings.fontSizeBodyText', '16px'));
};
exports.fontSizeBodyText = fontSizeBodyText;
var fontSizeSmall = function fontSizeSmall(props) {
  return _object.default.get(props, 'theme.style.fontSizeSmall', _object.default.get(props, 'theme.settings.fontSizeSmall', '14px'));
};
exports.fontSizeSmall = fontSizeSmall;
var fontSizeStatusBar = function fontSizeStatusBar(props) {
  return _object.default.get(props, 'theme.style.fontSizeStatusBar', _object.default.get(props, 'theme.settings.fontSizeStatusBar', '0.75em'));
};
exports.fontSizeStatusBar = fontSizeStatusBar;
var blogTitle = function blogTitle(props) {
  return _object.default.get(props, 'theme.settings.blogTitle', '40px');
};
exports.blogTitle = blogTitle;
var fontWeight = function fontWeight(props) {
  return _object.default.get(props, 'theme.settings.fontWeight', 'bold');
};

// Buttons
exports.fontWeight = fontWeight;
var buttonBgColor = function buttonBgColor(props) {
  return _object.default.get(props, 'theme.style.accentColor', _object.default.get(props, 'theme.settings.buttonBgColor', '#00B2E8'));
};
exports.buttonBgColor = buttonBgColor;
var buttonBorder = function buttonBorder(props) {
  return _object.default.get(props, 'theme.settings.buttonBorder', '0.5px solid #111111');
};
exports.buttonBorder = buttonBorder;
var buttonBoxShadow = function buttonBoxShadow(props) {
  return _object.default.get(props, 'theme.settings.buttonBoxShadow', '1px 1px 0.5px 0 rgba(70, 70, 70, 0.3)');
};
// Text
exports.buttonBoxShadow = buttonBoxShadow;
var buttonTextColor = function buttonTextColor(props) {
  return _object.default.get(props, 'theme.settings.buttonTextColor', '#ffffff');
};
exports.buttonTextColor = buttonTextColor;
var buttonFont = function buttonFont(props) {
  return _object.default.get(props, 'theme.settings.buttonFont', "'Helvetica Neue', 'Helvetica', 'Arial', 'Sans-serif'");
};
exports.buttonFont = buttonFont;
var buttonFontSize = function buttonFontSize(props) {
  return _object.default.get(props, 'theme.settings.buttonFontSize', '16px');
};

// Hover
exports.buttonFontSize = buttonFontSize;
var buttonBgColorHover = function buttonBgColorHover(props) {
  return _object.default.get(props, 'theme.settings.buttonBgColorHover', '#00B2E8');
};
exports.buttonBgColorHover = buttonBgColorHover;
var buttonHoverTextColor = function buttonHoverTextColor(props) {
  return _object.default.get(props, 'theme.settings.buttonHoverTextColor', '#ffffff');
};

// Active
exports.buttonHoverTextColor = buttonHoverTextColor;
var buttonBgColorActive = function buttonBgColorActive(props) {
  return _object.default.get(props, 'theme.settings.buttonBgColorActive', 'rgba(0, 178, 232, 0.5)');
};
exports.buttonBgColorActive = buttonBgColorActive;
var buttonActiveTextColor = function buttonActiveTextColor(props) {
  return _object.default.get(props, 'theme.style.accentColor', _object.default.get(props, 'theme.settings.buttonActiveTextColor', '#00B2E8'));
};

// Inverted
exports.buttonActiveTextColor = buttonActiveTextColor;
var buttonBgColorInverted = function buttonBgColorInverted(props) {
  return _object.default.get(props, 'theme.settings.buttonBgColorInverted', 'none');
};
exports.buttonBgColorInverted = buttonBgColorInverted;
var buttonBgColorHoverInverted = function buttonBgColorHoverInverted(props) {
  return _object.default.get(props, 'theme.settings.buttonBgColorHoverInverted', 'rgba(0, 182, 124, 0.1)');
};
exports.buttonBgColorHoverInverted = buttonBgColorHoverInverted;
var buttonBgColorActiveInverted = function buttonBgColorActiveInverted(props) {
  return _object.default.get(props, 'theme.settings.buttonBgColorActiveInverted', 'rgba(0, 182, 124, 0.5)');
};

// Event color
exports.buttonBgColorActiveInverted = buttonBgColorActiveInverted;
var AccentColor = function AccentColor(props) {
  return _object.default.get(props, 'theme.style.accentColor', _object.default.get(props, 'theme.settings.AccentColor', '#00B2E8'));
};
exports.AccentColor = AccentColor;
var SecondAccentColor = function SecondAccentColor(props) {
  return _object.default.get(props, 'theme.settings.SecondAccentColor', '#B1B1B1');
};
exports.SecondAccentColor = SecondAccentColor;
var pollTextColor = function pollTextColor(props) {
  return _object.default.get(props, 'theme.settings.pollTextColor', '#ffffff');
};

// Arrow color
exports.pollTextColor = pollTextColor;
var BackArrowColor = function BackArrowColor(props) {
  return _object.default.get(props, 'theme.settings.BackArrowColor', '#aaa293');
};
exports.BackArrowColor = BackArrowColor;
var arrow = 'content: "";position: absolute;display: inline-block; vertical-align: top; transform: rotate(-45deg); top: calc(55% - 10px); right: 20px; width: 10px; height: 10px; border-right: 2px solid #00B2E8; border-bottom: 2px solid #00B2E8;';
exports.arrow = arrow;
var profileImageSize = {
  tiny: '25px',
  comment: '35px' /* FIXME: remove this */,
  small: '50px',
  medium: '80px',
  large: '200px'
};
exports.profileImageSize = profileImageSize;
var profileInitialsFontSize = {
  tiny: '12px',
  comment: '12px' /* FIXME: remove this */,
  small: '24px',
  medium: '30px',
  large: '75px'
};
exports.profileInitialsFontSize = profileInitialsFontSize;
var zIndexes = {
  /**
   * Above everything else
   */
  onTop: 1000,
  /**
   * Dialogs
   */
  dialog: 500,
  /**
   * Profile popup
   */
  profilePopup: 250,
  /**
   * User menu
   */
  userMenu: 100
};
exports.zIndexes = zIndexes;
var _default = {
  colorTitle: colorTitle,
  colorText: colorText,
  buttonBgColor: buttonBgColor,
  colorLight: colorLight,
  backgroundColor: backgroundColor,
  discreteBackgroundColor: discreteBackgroundColor,
  fontNormal: fontNormal,
  fontTitle: fontTitle,
  fontSizeBodyText: fontSizeBodyText,
  fontSizeSmall: fontSizeSmall,
  fontSizeStatusBar: fontSizeStatusBar,
  h1: h1,
  h2: h2,
  h3: h3,
  h4: h4,
  profileImageSize: profileImageSize,
  profileInitialsFontSize: profileInitialsFontSize,
  zIndexes: zIndexes
};
exports.default = _default;