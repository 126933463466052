"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ShopItems = void 0;
var _styledComponents = require("styled-components");
var _ProductListingItem = require("@stackend/react/shop/ProductListingItem.style");
var _Product = require("@stackend/react/shop/Product.style");
var _Shop = require("@stackend/react/shop/Shop.style");
var _ProductImageBrowser = require("@stackend/react/shop/ProductImageBrowser.style");
var sc = _interopRequireWildcard(require("../../style-common/styled-component-variables.js"));
var _media = require("../media");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
/**
 * Styling for shop items when they appear in rich content
 */
var ShopItems = function ShopItems() {
  return (0, _styledComponents.css)(["", ",", "{margin:1em 0;border-radius:", ";}", "{> .stackend-product-link{width:auto;}}.stackend-shop-product-module-horizontal{", "{.stackend-product-link{padding:0;}}}", "{", "{margin:0.5em 0;}", "{grid-template-rows:minmax(1em,min-content) minmax(1em,min-content) 1fr;}", "{", "{margin:0 0.5em;}}}", "{", "{margin-left:1em;}> a > img{width:100%;}}", "{list-style:none;> li{display:inline-block;margin:0.5em;button{cursor:pointer;}}}"], _ProductListingItem.ProductListingItem, _Product.Product, function (props) {
    return props.theme.borderRadius;
  }, _ProductListingItem.ProductListingItem, _ProductListingItem.ProductListingItem, _Product.Product, _Shop.Title, _media.media.overMobile, _media.media.mobileScreen, _Shop.Description, _ProductImageBrowser.ProductImageBrowser, _media.media.overMobile, _ProductImageBrowser.ProductImageBrowserThumbnails);
};
exports.ShopItems = ShopItems;