"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styledComponents = require("styled-components");
var _stackendCmsEditable = require("./stackend-cms-editable");
var _Page = require("../cms/Page.style");
var _Subsite = require("../cms/Subsite.style");
var _StackendTheme = _interopRequireWildcard(require("@stackend/react/theme/StackendTheme"));
var _ComponentState = _interopRequireDefault(require("@stackend/react/theme/ComponentState"));
var _ComponentType = _interopRequireWildcard(require("@stackend/react/theme/ComponentType"));
var _materialIcons = require("./material-icons.js");
var _ReactToastify = _interopRequireDefault(require("react-toastify/dist/ReactToastify.css"));
var _ToastsStyle = require("./Toasts.style.js");
var _StackendReactSelectStyle = require("./StackendReactSelectStyle");
var _StackendReactModal = _interopRequireDefault(require("./StackendReactModal.style"));
var _bootstrap = _interopRequireDefault(require("rc-tooltip/assets/bootstrap.css"));
var _Product = require("@stackend/react/shop/Product.style");
var _Shop = require("@stackend/react/shop/Shop.style");
var _ShopifyAppGlobalStyle = _interopRequireWildcard(require("./ShopifyAppGlobalStyle.style"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
//import reset from 'styled-reset';
/**
 * Global style for stackend modules
 */
var StackendModulesGlobalStyle = (0, _styledComponents.createGlobalStyle)(["", " ", " ", " .stackend{display:block;width:auto;height:auto;margin:0;padding:0;border:none;box-sizing:border-box;text-align:left;", " ", " ", ";", " div,p,form,ul,li,ol,fieldset,menu{box-sizing:border-box;margin:0;padding:0;}tt,pre,code{font-family:monospace;}em{font-style:italic;}strong,b{font-weight:bold;}audio{width:100%;height:auto;min-height:30px;}fieldset{border:none;}h1,h2,h3,h4,h5,h6{", ";", ";}h1{", " ", "}h2{", " ", "}h3{", " ", "}h4,h5,h6{", " ", "}a{", "}input,select{", ";}button,input[type='button'],input[type='submit'],input[type='reset'],.stackend-button{", " ", " ", " ", " ", " ", ";cursor:pointer;display:inline-flex;align-items:center;justify-content:center;gap:", ";user-select:none;&:disabled{cursor:not-allowed;}&[type='submit']{", " ", "}&.stackend-positive-action{", " ", "}&.stackend-danger-action{", " ", "}&.stackend-icon,&.icon{border:none;background:none;padding:", " ", ";color:", ";&:hover{background:none;}}}.stackend-button{", " ", " padding:", ";}textarea,input:not([type]),input[type='date'],input[type='datetime-local'],input[type='email'],input[type='month'],input[type='number'],input[type='password'],input[type='search'],input[type='tel'],input[type='time'],input[type='text'],input[type='url'],input[type='week']{", " ", ";&::placeholder{", "}}input[type=\"checkbox\"],input[type=\"radio\"]{", " ", ";}input[type=\"radio\"]:after{border-radius:100%;}input[type=\"radio\"]:focus:after{outline:1px solid red;}select{", " ", " ", " ", " ", ";}.stackend-box{", " padding:", ";}", " ", " ", " ", "{", "{display:none;}}", " ", " ", " ", " ", "  ", " ", ";}", ";"], function (props) {
  return !props.theme.applyToCmsContent && _stackendCmsEditable.StackendCmsEditable;
}, function (props) {
  return !props.theme.applyToCmsContent && _Page.Page;
}, function (props) {
  return !props.theme.applyToCmsContent && _Subsite.Subsite;
}, function (props) {
  return fontFamily(props.theme);
}, function (props) {
  return fontSize(props.theme);
}, function (props) {
  return fontWeight(props.theme);
}, function (props) {
  return defaultColors(props.theme);
}, function (props) {
  return fontFamily(props.theme, 'heading');
}, function (props) {
  return fontWeight(props.theme, 'heading');
}, function (props) {
  return headingFontSize(props.theme, _ComponentType.default.H1);
}, function (props) {
  return headingColor(props.theme, _ComponentType.default.H1);
}, function (props) {
  return headingFontSize(props.theme, _ComponentType.default.H2);
}, function (props) {
  return headingColor(props.theme, _ComponentType.default.H2);
}, function (props) {
  return headingFontSize(props.theme, _ComponentType.default.H3);
}, function (props) {
  return headingColor(props.theme, _ComponentType.default.H3);
}, function (props) {
  return headingFontSize(props.theme, _ComponentType.default.H4);
}, function (props) {
  return headingColor(props.theme, _ComponentType.default.H4);
}, function (props) {
  return linkStyle(props.theme, true);
}, function (props) {
  return props.theme.inheritFonts ? '' : 'font-size: 1rem;';
}, function (props) {
  return hoverStyle(props.theme, _ComponentType.default.BUTTON, true);
}, function (props) {
  return componentStyle(props.theme, _ComponentType.default.BUTTON, true);
}, function (props) {
  return fontFamily(props.theme, 'control');
}, function (props) {
  return fontWeight(props.theme, 'control');
}, function (props) {
  return fontSize(props.theme, 'control');
}, function (props) {
  return !props.theme.inheritMargins && (0, _styledComponents.css)(["padding:", ";"], props.theme.margins.small + ' ' + props.theme.margins.medium);
}, function (props) {
  return props.theme.margins.small;
}, function (props) {
  return hoverStyle(props.theme, _ComponentType.default.BUTTON_DEFAULT, true);
}, function (props) {
  return componentStyle(props.theme, _ComponentType.default.BUTTON_DEFAULT, true);
}, function (props) {
  return props.theme.inheritColors ? '' : hoverStyle(props.theme, _ComponentType.default.BUTTON_DEFAULT);
}, function (props) {
  return props.theme.inheritColors ? partialComponentStyle(props.theme, _ComponentType.default.BUTTON_DEFAULT) : componentStyle(props.theme, _ComponentType.default.BUTTON_DEFAULT);
}, function (props) {
  return props.theme.inheritColors ? '' : hoverStyle(props.theme, _ComponentType.default.BUTTON_DANGER);
}, function (props) {
  return props.theme.inheritColors ? partialComponentStyle(props.theme, _ComponentType.default.BUTTON_DANGER) : componentStyle(props.theme, _ComponentType.default.BUTTON_DANGER);
}, function (props) {
  return props.theme.margins.small;
}, function (props) {
  return props.theme.margins.small;
}, function (props) {
  return props.theme.color;
}, function (props) {
  return hoverStyle(props.theme, _ComponentType.default.BUTTON, false);
}, function (props) {
  return componentStyle(props.theme, _ComponentType.default.BUTTON, false);
}, function (props) {
  return props.theme.margins.small + ' ' + props.theme.margins.medium;
}, function (props) {
  return componentStyle(props.theme, _ComponentType.default.TEXT_INPUT, true);
}, function (props) {
  return !props.theme.inheritMargins && (0, _styledComponents.css)(["padding:", ";"], props.theme.margins.small);
}, function (props) {
  return !props.theme.inheritColors && (0, _styledComponents.css)(["color:", ";"], function (props) {
    return (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.TEXT_INPUT, 'color', _ComponentState.default.DISABLED);
  });
}, function (props) {
  return !props.theme.inheritColors && (0, _styledComponents.css)(["accent-color:", ";"], (0, _StackendTheme.getComponentProp)(props.theme, _ComponentType.default.ACCENT, 'backgroundColor'));
}, function (props) {
  return !props.theme.inheritMargins && (0, _styledComponents.css)(["width:1rem;height:1rem;margin:0;"]);
}, function (props) {
  return componentStyle(props.theme, _ComponentType.default.SELECT, true);
}, function (props) {
  return fontFamily(props.theme, 'control');
}, function (props) {
  return fontWeight(props.theme, 'control');
}, function (props) {
  return fontSize(props.theme, 'control');
}, function (props) {
  return !props.theme.inheritMargins && (0, _styledComponents.css)(["padding:", " ", " ", " ", ";"], props.theme.margins.small, props.theme.margins.medium, props.theme.margins.small, props.theme.margins.small);
}, function (props) {
  return componentStyle(props.theme, _ComponentType.default.BOX, false);
}, function (props) {
  return props.theme.margins.medium;
}, function (props) {
  return props.theme.applyToCmsContent && _stackendCmsEditable.StackendCmsEditable;
}, function (props) {
  return props.theme.applyToCmsContent && _Page.Page;
}, function (props) {
  return props.theme.applyToCmsContent && _Subsite.Subsite;
}, _Product.Product, _Shop.Tags, _materialIcons.MaterialIcons, _ReactToastify.default, _ToastsStyle.Toasts, _StackendReactModal.default, _bootstrap.default, _StackendReactSelectStyle.STACKEND_REACT_SELECT, function (props) {
  return props.theme.shopifyApp && _ShopifyAppGlobalStyle.default;
}, function (props) {
  return props.theme.shopifyApp && _ShopifyAppGlobalStyle.ShopifyAppGlobalUnprefixedStyle;
});
var _default = StackendModulesGlobalStyle;
exports.default = _default;
function defaultFontsAndColors(theme) {
  var f = theme.inheritFonts ? '' : (0, _styledComponents.css)(["font-family:", ";font-size:", ";font-weight:", ";"], theme.fontFamily, theme.fontSize, theme.fontWeight);
  var c = theme.inheritColors ? '' : (0, _styledComponents.css)(["background:", ";color:", ";"], theme.backgroundColor, theme.color);
  return f + c;
}
function defaultColors(theme) {
  if (theme.inheritColors) {
    return '';
  }
  return (0, _styledComponents.css)(["background:", ";color:", ";"], theme.backgroundColor, theme.color);
}
function fontFamily(theme, componentType) {
  if (theme.inheritFonts) {
    return '';
  }
  var f = componentType ? theme[componentType + 'FontFamily'] : theme['fontFamily'];
  if (!f) {
    return '';
  }
  return (0, _styledComponents.css)(["font-family:", ";"], f);
}
function fontSize(theme, componentType) {
  if (theme.inheritFonts) {
    return '';
  }
  var s = componentType ? theme[componentType + 'FontSize'] : theme['fontSize'];
  if (!s) {
    return '';
  }
  return (0, _styledComponents.css)(["font-size:", ";"], s);
}
function fontWeight(theme, componentType) {
  if (theme.inheritFonts) {
    return '';
  }
  var s = componentType ? theme[componentType + 'FontWeight'] : theme['fontWeight'];
  if (!s) {
    return '';
  }
  return (0, _styledComponents.css)(["font-weight:", ";"], s);
}
function headingFontSize(theme, componentType) {
  if (theme.inheritFonts) {
    return '';
  }
  var sz = (0, _StackendTheme.headingFontSize)(theme, componentType);
  return (0, _styledComponents.css)(["font-size:", ";"], sz);
}
function headingColor(theme, componentType) {
  if (theme.inheritColors) {
    return '';
  }
  return (0, _styledComponents.css)(["color:", ";"], (0, _StackendTheme.getComponentProp)(theme, componentType, 'color'));
}
function componentStyle(theme, componentType, prefferInheritedColors) {
  if (!!prefferInheritedColors && theme.inheritColors) {
    return '';
  }
  return (0, _styledComponents.css)(["background:", ";color:", ";", " &:focus{background:", ";color:", ";border-color:", ";outline:", " solid ", ";outline-offset:0px;}&:disabled,&:disabled:hover{background:", ";color:", ";border-color:", ";}&::placeholder{color:", ";}"], (0, _StackendTheme.getComponentProp)(theme, componentType, 'backgroundColor', _ComponentState.default.NORMAL), (0, _StackendTheme.getComponentProp)(theme, componentType, 'color', _ComponentState.default.NORMAL), borderStyle(theme, componentType), (0, _StackendTheme.getComponentProp)(theme, componentType, 'backgroundColor', _ComponentState.default.FOCUS), (0, _StackendTheme.getComponentProp)(theme, componentType, 'color', 'focus'), (0, _StackendTheme.getComponentProp)(theme, componentType, 'borderColor', _ComponentState.default.FOCUS), theme.borderWidth, (0, _StackendTheme.getComponentProp)(theme, componentType, 'outlineColor', _ComponentState.default.FOCUS) || 'none', (0, _StackendTheme.getComponentProp)(theme, componentType, 'backgroundColor', _ComponentState.default.DISABLED), (0, _StackendTheme.getComponentProp)(theme, componentType, 'color', _ComponentState.default.DISABLED), (0, _StackendTheme.getComponentProp)(theme, componentType, 'borderColor', _ComponentState.default.DISABLED), (0, _StackendTheme.getComponentProp)(theme, componentType, 'color', _ComponentState.default.DISABLED));
}
function partialComponentStyle(theme, componentType, prefferInheritedColors) {
  if (!!prefferInheritedColors && theme.inheritColors) {
    return '';
  }
  return (0, _styledComponents.css)(["background:", ";color:", ";&:focus{background:", ";color:", ";}&:disabled,&:disabled:hover{background:", ";color:", ";}"], (0, _StackendTheme.getComponentProp)(theme, componentType, 'backgroundColor', 'normal'), (0, _StackendTheme.getComponentProp)(theme, componentType, 'color', 'normal'), (0, _StackendTheme.getComponentProp)(theme, componentType, 'backgroundColor', 'focus'), (0, _StackendTheme.getComponentProp)(theme, componentType, 'color', 'focus'), (0, _StackendTheme.getComponentProp)(theme, componentType, 'backgroundColor', 'disabled'), (0, _StackendTheme.getComponentProp)(theme, componentType, 'color', 'disabled'));
}
function usePadding(theme, componentType) {
  if (theme.inheritMargins && (0, _ComponentType.isStandardComponent)(componentType)) {
    return false;
  }
  return true;
}
function linkStyle(theme, prefferInheritedColors) {
  if (!!prefferInheritedColors && theme.inheritColors) {
    return '';
  }
  return (0, _styledComponents.css)(["color:", ";", ";&:focus{color:", ";outline:", " solid ", ";}&:hover{color:", ";}&:visited{color:", ";}"], (0, _StackendTheme.getComponentProp)(theme, _ComponentType.default.LINK, 'color', 'normal'), theme.borderRadius !== '0px' ? 'border-radius: ' + theme.borderRadius + ';\n' : '', (0, _StackendTheme.getComponentProp)(theme, _ComponentType.default.LINK, 'color', 'focus'), theme.borderWidth, (0, _StackendTheme.getComponentProp)(theme, _ComponentType.default.LINK, 'outlineColor', 'focus'), (0, _StackendTheme.getComponentProp)(theme, _ComponentType.default.LINK, 'color', 'hover'), (0, _StackendTheme.getComponentProp)(theme, _ComponentType.default.LINK, 'color', 'normal'));
}
function hoverStyle(theme, component, prefferInheritedColors) {
  if (!!prefferInheritedColors && theme.inheritColors) {
    return '';
  }
  return (0, _styledComponents.css)(["&:hover{background:", ";color:", ";border-color:", ";}"], (0, _StackendTheme.getComponentProp)(theme, component, 'backgroundColor', _ComponentState.default.ACTIVE_HOVER), (0, _StackendTheme.getComponentProp)(theme, component, 'color', _ComponentState.default.ACTIVE_HOVER), (0, _StackendTheme.getComponentProp)(theme, component, 'borderColor', _ComponentState.default.ACTIVE_HOVER));
}
function borderStyle(theme, componentType) {
  if (theme.inheritMargins && (0, _ComponentType.isStandardComponent)(componentType)) {
    return '';
  }
  theme.inheritMargins;
  var ct = theme[componentType];
  return (0, _styledComponents.css)(["border:", " solid ", ";", ""], theme.borderWidth, ct && ct.borderColor ? ct.borderColor : theme.borderColor, theme.borderRadius !== '0px' ? 'border-radius: ' + theme.borderRadius + ';\n' : '');
}