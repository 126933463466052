"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = registerStackendBlots;
var _react = _interopRequireDefault(require("react"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function registerStackendBlots(Quill, features) {
  var icons = Quill.import('ui/icons');
  if (!Quill.imports['formats/xcap-responsive-subsite']) {
    Quill.register(require('./StackendResponsiveSubsiteBlot').StackendResponsiveSubsiteBlot);
  }
  if (!Quill.imports['formats/stackend-audio-video']) {
    Quill.register(require('./StackendAudioVideoBlot').StackendAudioVideoBlot);
  }
  if (!Quill.imports['formats/xcap-site-preview']) {
    Quill.register(require('./StackendSitePreviewBlot').StackendSitePreviewBlot);
  }

  // Install custom icons
  if (features !== null && features !== void 0 && features.shop) {
    // Quill API Will only work client side since it uses document

    if (icons && !icons['product']) {
      icons['product'] = "<i class='material-icons'>shopping_basket</i>";
    }
    if (!Quill.imports['formats/product']) {
      Quill.register(require('./StackendProductBlot').StackendProductBlot);
    }
  }

  // Hack to allow style attribute on image tags
  Quill.register(require('./ImageBlot').default, true);

  // Drop images as blob: urls. Backend handles these
  if (!Quill.imports['modules/imageDrop']) {
    Quill.register('modules/imageDrop', require('quill-image-drop-module').ImageDrop);
  }
  if (!Quill.imports['modules/magicUrl']) {
    Quill.register('modules/magicUrl', require('quill-magic-url').default);
  }

  // For image resize. Needs some backend improvements to ResponsiveMediaInputProcessor to not be overridden.

  if (!Quill.imports['modules/blotFormatter']) {
    Quill.register('modules/blotFormatter', require('quill-blot-formatter').default);
  }

  /* Not complete
  if (!Quill.imports['modules/boxlayout']) {
  	Quill.register(
  		'modules/boxlayout',
  		require('./quill-box-layout/modules/BoxLayoutUi').BoxLayoutUi
  	);
  }*/
}