"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addModuleHandler = addModuleHandler;
exports.getModuleHandler = getModuleHandler;
exports.getModuleHandlers = getModuleHandlers;
exports.removeModuleHandler = removeModuleHandler;
var _ModuleHandler = _interopRequireDefault(require("./ModuleHandler"));
var _ProfileModuleHandler = _interopRequireDefault(require("./handlers/ProfileModuleHandler"));
var _FeedModuleHandler = _interopRequireDefault(require("./handlers/FeedModuleHandler"));
var _CommentsModuleHandler = _interopRequireDefault(require("./handlers/CommentsModuleHandler"));
var _LoginModuleHandler = _interopRequireDefault(require("./handlers/LoginModuleHandler"));
var _CmsModuleHandler = _interopRequireDefault(require("./handlers/CmsModuleHandler"));
var _ThrobberModuleHandler = _interopRequireDefault(require("./handlers/ThrobberModuleHandler"));
var _SlideshowModuleHandler = _interopRequireDefault(require("./handlers/SlideshowModuleHandler"));
var _LiveEventModuleHandler = _interopRequireDefault(require("./handlers/LiveEventModuleHandler"));
var _LiveEventListingModuleHandler = _interopRequireDefault(require("./handlers/LiveEventListingModuleHandler"));
var _SubsiteModuleHandler = require("./handlers/SubsiteModuleHandler");
var _PageModuleHandler = _interopRequireDefault(require("./handlers/PageModuleHandler"));
var _ProductModuleHandler = _interopRequireDefault(require("./handlers/ProductModuleHandler"));
var _ProductCollectionModuleHandler = _interopRequireDefault(require("./handlers/ProductCollectionModuleHandler"));
var _ProductListingModuleHandler = _interopRequireDefault(require("./handlers/ProductListingModuleHandler"));
var _BasketModuleHandler = _interopRequireDefault(require("./handlers/BasketModuleHandler"));
var _GlobalStyleResetModuleHandler = _interopRequireDefault(require("./handlers/GlobalStyleResetModuleHandler"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//import BlogModuleHandler from './handlers/BlogModuleHandler';
var MODULE_HANDLERS = {};

/**
 * Get the module handler for a module type
 * @param moduleType
 * @returns {null}
 */
function getModuleHandler(moduleType) {
  var m = MODULE_HANDLERS[moduleType];
  return m || null;
}

/**
 * Add a module handler
 * @param handler
 */
function addModuleHandler(handler) {
  MODULE_HANDLERS[handler.moduleType] = handler;
}

/**
 * Remove a module handler
 * @param moduleType
 */
function removeModuleHandler(moduleType) {
  delete MODULE_HANDLERS[moduleType];
}

/**
 * Get all module handlers
 */
function getModuleHandlers() {
  return Object.values(MODULE_HANDLERS);
}
addModuleHandler(new _GlobalStyleResetModuleHandler.default());
addModuleHandler(new _ThrobberModuleHandler.default());
addModuleHandler(new _LoginModuleHandler.default());
addModuleHandler(new _ProfileModuleHandler.default());
addModuleHandler(new _CmsModuleHandler.default());
addModuleHandler(new _SubsiteModuleHandler.SubsiteModuleHandler());
addModuleHandler(new _PageModuleHandler.default());
addModuleHandler(new _FeedModuleHandler.default());
//addModuleHandler(new BlogModuleHandler());
addModuleHandler(new _CommentsModuleHandler.default());
addModuleHandler(new _SlideshowModuleHandler.default());
addModuleHandler(new _ProductModuleHandler.default());
addModuleHandler(new _ProductCollectionModuleHandler.default());
addModuleHandler(new _ProductListingModuleHandler.default());
addModuleHandler(new _BasketModuleHandler.default());
addModuleHandler(new _LiveEventModuleHandler.default());
addModuleHandler(new _LiveEventListingModuleHandler.default());