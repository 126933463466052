"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stackendModuleReducer = exports.STACKEND_MODULE_REDUCERS = void 0;
var _redux = require("redux");
var _SetBodyClassReducer = require("../reducers/SetBodyClassReducer.jsx");
var _commentUIReducer = require("../comments/commentUIReducer.js");
var _modalReducer = _interopRequireDefault(require("../xcap/modalReducer.js"));
var _accordionReducer = _interopRequireDefault(require("../Accordion/accordionReducer.jsx"));
var _analyticsReducer = _interopRequireDefault(require("../analytics/analyticsReducer.js"));
var _cmsEditorReducer = _interopRequireDefault(require("../cms/edit-in-place/cmsEditorReducer.js"));
var _shopUiReducer = _interopRequireDefault(require("@stackend/react/shop/shopUiReducer"));
var _blogUiReducer = require("../blog/blogUiReducer");
var _reducers = require("@stackend/api/api/reducers");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var r = _objectSpread(_objectSpread({}, _reducers.STANDARD_REDUCERS), {}, {
  //routing, // FIXME: Remove this?

  accordionReducer: _accordionReducer.default,
  /* faq */

  openBlogEntryWriteCommentSection: _blogUiReducer.openBlogEntryWriteCommentSection,
  openCommentSection: _commentUIReducer.openCommentSection,
  openEditComment: _commentUIReducer.openEditComment,
  openReplyBoxes: _commentUIReducer.openReplyBoxes,
  /*
  qnaAvailableFilters,
  qnaGame,
  qnaPage,
  qnaSearchResult,
  qnaSelectedFilters,
  qnaServer,
  qnaStyling,
  */

  /* Used by forum only: recaptchaReducer, */

  SetBodyClassReducer: _SetBodyClassReducer.SetBodyClassReducer,
  /* Disabled not used: topListReducer, */
  /* Disabled not used : notifications, */
  analyticsReducer: _analyticsReducer.default,
  openModals: _modalReducer.default,
  cmsEditInPlace: _cmsEditorReducer.default,
  shopUi: _shopUiReducer.default
});

/* remove some unused */
delete r.forums;
delete r.forumThreads;
delete r.editForumThread;
delete r.search;
delete r.qna;
delete r.categories;
var STACKEND_MODULE_REDUCERS = r;
exports.STACKEND_MODULE_REDUCERS = STACKEND_MODULE_REDUCERS;
var reducers = (0, _redux.combineReducers)(STACKEND_MODULE_REDUCERS);

/**
 * The combined reducer used for stackend modules
 * @param state
 * @param action
 * @returns {*}
 */
var stackendModuleReducer = function stackendModuleReducer(state, action) {
  if (action.type === 'EMPTY_STORE') {
    state = undefined;
  }
  return reducers(state, action);
};
exports.stackendModuleReducer = stackendModuleReducer;